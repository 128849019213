/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback, useEffect, useState,
} from 'react'
import _ from 'lodash'
import { cancelRequest, useCouponTokens } from 'react-omnitech-api'
import { useAlert, useLink, useThemeConfig } from '../../hook'
import PublicCouponsView from './public-coupons-view'

function PublicCouponsController(props) {
  // waiting API implementation
  const { qrcode: qrCodeValue } = props
  // prepare hook
  const alert = useAlert()
  const { recordNotFound } = useLink()
  const { getConfig } = useThemeConfig()
  const { fetchCouponTokenByQrCode } = useCouponTokens()

  // internal states
  const [couponToken, setCouponToken] = useState({})
  const [loading, setLoading] = useState(true)

  // locale variable
  const seoTitle = _.get(couponToken, 'coupon.name', '-')
  const seoDescription = _.get(couponToken, 'coupon.excerpt')
  const enableClock = getConfig('config.pages.publicCoupons.enableClock', false)

  /**
   * fetchCouponTokenByQrCodeApi
   * get article content group data from API
   */
  const fetchCouponTokenByQrCodeApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        qrCodeValue,
        includes: ['can_actions', 'coupon', 'coupons.images'].join(','),
      }
      // call api
      const { couponToken: data } = await fetchCouponTokenByQrCode(option)
      setCouponToken(data)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound()
        return
      }
      alert.show(generalError.message)
    } finally {
      setLoading(false)
    }
  }, [fetchCouponTokenByQrCode, qrCodeValue])

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchCouponTokenByQrCodeApi()

    return function fetchPageApiCleanUp() {
      cancelRequest.cancelAll(['fetchCouponTokenByQrCode'])
    }
  }, [fetchCouponTokenByQrCode])

  const viewProps = {
    ..._.pick(couponToken, [
      'canActions',
      'coupon',
      'status',
      'statusLabel',
      'startAt',
      'computedGracePeriodExpiresAt',
    ]),
    enableClock,
    loading,
    qrCodeValue,
    seoDescription,
    seoTitle,
  }

  return (
    <PublicCouponsView {...viewProps} />
  )
}

export default PublicCouponsController
