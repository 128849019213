import { createUseStyles } from 'react-jss'
import Color from 'color'

const useStyles = createUseStyles((theme) => ({
  contianer: {
    background: theme.colors.background,
    width: '100%',
    maxWidth: 500,
    margin: [[50, 'auto']],
  },
  coupon: {
    borderLeft: 'solid 1px #f2f2f2',
    borderRight: 'solid 1px #f2f2f2',
  },
  terms: {
    margin: [[50, 'auto']],
    '& h4': {
      fontSize: '1.6rem',
      margin: 0,
      fontWeight: 600,
      marginBottom: '2.1rem',
    },
    '& p': {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      color: Color(theme.colors.text).alpha(0.8).string(),
    },
    '& ul': {
      listStyle: 'disc',
      marginLeft: '2rem',
    },
    '& li': {
      color: Color(theme.colors.text).alpha(0.8).string(),
    },
  },
}))

export default useStyles
