import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import PublicCouponsScreen from '../screens/public-coupons'

const PublicCouponsPage = (props) => (
  <Router>
    <PublicCouponsScreen {...props} path={`${_.get(props, 'pageContext.locale')}/public-coupons/:qrcode`} />
  </Router>
)

export default PublicCouponsPage
